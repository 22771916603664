@font-face {
  font-family: 'Roboto Slab Medium';
  src: url('./fonts/Roboto_Slab/static/RobotoSlab-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Slab Regular';
  src: url('./fonts/Roboto_Slab/static/RobotoSlab-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Slab Bold';
  src: url('./fonts/Roboto_Slab/static/RobotoSlab-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Black';
  src: url('./fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Light';
  src: url('./fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Roboto Slab Medium';
  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #000;
  text-align: center;
}

header {
  background: #fff;
  color: #000;
  padding: 20px 0;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

header h1 {
  font-size: 2.5em;
  margin: 0;
  font-weight: bold;
  line-height: 2em;
}

header p {
  margin: 10px 0;
  font-size: 1.2em;
  line-height: 2em;
  font-family: 'Roboto Slab Bold';
}

main {
  padding: 20px;
  text-align: center;
}

.location {
  margin: 10px 0;
  line-height: 3em;
}

#region-select {
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 1em;
  font-size: 1em;
}

#go-button {
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 1em;
}

.stroybrigadamo {
}

.container {
  margin: auto;
  width: 90%;
  /* margin-left: 0px;
  margin-right: 0px; */
  /*padding-left: 10px;
  padding-right: 10px;*/
}

.phone-number {
  font-family: 'Roboto Black';
  font-size: 2.5em;
  margin: 20px 0;
  line-height: 0.7em;
}

.description {
  font-size: 1.2em;
  margin: 15px 0;
  line-height: 1.5em;
  font-family: 'Roboto Light';
}

strong {
  font-family: 'Roboto Black';
}

button {
  background: #fff;
  color: #000;
  border: 2px solid #000;
  padding: 15px 40px;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5em;
  margin-top: 10px;
  text-transform: uppercase;
  line-height: 1em;
}

button:hover {
  background: #000;
  color: #fff;
}

.section {
  margin: 40px 0;
  text-align: center;
}

.section h2 {
  font-size: 2em;
  margin-bottom: 20px;
  max-width: 22em; /* Ограничиваем максимальную ширину заголовка */
  margin: 0 auto; /* Автоматические отступы для центрирования */
  font-family: 'Roboto Slab Regular';
  line-height: 1.5em;
}

.section p {
  width: 80%; /* Увеличиваем ширину для центрирования */
  font-size: 1.1em;
  line-height: 1.6;
  text-align: center;
  margin: 10px auto; /* Автоматические отступы для центрирования */
}

.section .centered-list {
  display: inline-block;
  text-align: center;
}

.section ul {
  list-style-type: none;
  padding: 0;
}

.section ul li {
  margin: 10px 0;
  position: relative;
  padding-left: 1.2em;
  text-align: center;
}

.section ul li::before {
  content: '•';
  position: relative;
  left: -1%;
  top: 0;
  font-size: 1.2em;
  line-height: 1.2em;
  color: #000;
}

.calculator {
  text-align: center; /* Центрирование содержимого калькулятора */
  line-height: 2em;
  line-height: 3.5em;
}

.input-large {
  font-size: 1.4em;
  text-align: center; /* Центрирование содержимого */
  font-family: 'Roboto Slab Medium';
}

.calculation-table {
  margin-left: auto;
  margin-right: auto; /* Центрирование таблицы */
  border-collapse: collapse; /* Удаление пространства между границами ячеек */
  min-width: 22em;
}

.calculation-table th{
  border: 1px solid black; /* Черные границы для ячеек */
  padding: 8px;
  font-size: 1.2em;
}

.calculation-table td {
  border: 1px solid black; /* Черные границы для ячеек */
  padding: 8px;
  font-size: 1.5em;
}

.columns-calculator {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1em;
}

.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* gap: 20px; */
  /* max-width: 60em; */
  margin-top: 3em;
}

.column {
  flex: 1;
  /* min-width: 250px; */
  /* max-width: 33%; */
}

.column .list {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.column .list h3 {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
}

.column .list p {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
}


.column-third {
  flex: 1;
  min-width: 25%;
  max-width: 25%;
}



@media (max-width: 767px) {
  .column-third {
    min-width: 250px;
    max-width: 100%;
  }
}


.column-third .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 60em;
  margin: 0 auto; /* Центрирование контейнера */
  margin-top: 0.5em;
}

.column-third .list h3 {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
}

.column-third .list p {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
}

.mark-icon {
  width: 32px; /* Ширина значка */
  height: 32px; /* Высота значка */
  margin-bottom: 10px; /* Отступ снизу */
}

.main-about-us {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 50em;
  margin: 20px auto; /* Добавляем отступ сверху и снизу */
  align-items: center;
  /*justify-content: space-between; /* Отступы между текстом и изображением */
  /*justify-content: center;*/
}

.main-about-us h2 {
  font-size: 2em;
  margin-bottom: 20px;
  font-family: 'Roboto Slab';
}

.main-about-us-text {
  /*flex-grow: 1; /* Заполнение всего доступного пространства */
  justify-content: left; /* Центрирование по горизонтали */
  text-align: left;
  margin: 0;
  max-width: 24em;
}

.main-about-us-text p {
  font-size: 1.2em;
  text-align: left;
  margin: 0;
  line-height: 2em;
  font-family: 'Roboto Slab Regular';
}

.main-about-us ul {
  list-style-type: disc;
  padding-left: 40px;
  line-height: 2em;
  font-family: 'Roboto Slab Regular';
}

.main-about-us ul li {
  margin: 10px 0;
  font-size: 1.2em;
  position: relative;
  /* padding-left: 1.2em; */
  text-align: left;
}

.main-about-us-image {
  display: flex;
  justify-content: left; /* Центрирование по горизонтали */
  width: 20em;
  /*align-items: center; /* Центрирование по вертикали, если необходимо */
  /*width: 100%; /* Убедитесь, что контейнер занимает всю ширину */
  /*margin: 1;*/
}

.main-about-us-icon {
  width: 20em;
  height: auto;
}

.advantages {
  text-align: center;
  margin: 40px 0;
}

.advantages h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.advantages-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* max-width: 60em; */
  margin: 0 auto; /* Центрирование контейнера */
  margin-top: 3em;
}


@media (max-width: 767px) {
  .advantage-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 240px; */
    margin: 20px;
    padding: 20px;
    text-align: center;
    /* flex-basis: calc(40% - 2%); 40% ширины минус отступы */
    /* max-width: calc(40% - 2%); */
  }
}



.advantage-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  margin: 20px;
  padding: 20px;
  text-align: center; 
}

.advantage-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 10px;
}

.advantage-item h3 {
  font-size: 1.5em;
  margin: 10px 0;
}

.advantage-item p {
  font-size: 1em;
  line-height: 1.4;
  margin: 0;
}

.footer {
  background: #f4f4f4;
  padding: 20px 0;
  border-top: 1px solid #ddd;
}

.footer h3 {
  font-size: 2.5em;
  margin-bottom: 40px;
  text-align: center;
  max-width: 18em; /* Ограничиваем максимальную ширину заголовка */
  margin: 0 auto; /* Автоматические отступы для центрирования */
}

.footer button {
  background: #fff;
  color: #000;
  border: 2px solid #000;
  padding: 15px 40px;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5em;
  margin-top: 3em;
  margin-bottom: 3em;
  text-transform: uppercase;
  line-height: 1em;
}

.footer button:hover {
  background: #000;
  color: #fff;
}

.notFound-icon {
  width: 40em;
  height: auto;
}